export const linkData = (element) => {
  const link = element.closest('a')

  if (!element.dataset || !link) {
    return
  }

  if (element.dataset.trackingEventType) {
    return dataSetBuilder(element)
  } else {
    return {
      eventName: 'navigation_click',
      property: 'navigation',
      label: `${link.href} ${link.text}`,
    }
  }
}

const dataSetBuilder = (element) => {
  const {
    trackingEventType,
    trackingElementType,
    trackingElementName,
    trackingContentType,
    trackingContentId,
  } = element.dataset

  const value = isNaN(trackingContentId)
    ? undefined
    : parseInt(trackingContentId, 10)

  const label = trackingContentType
    ? `${trackingContentType} ${trackingElementName}`
    : trackingElementName

  return {
    eventName: trackingEventType,
    property: trackingElementType,
    label,
    value,
  }
}
