import { linkData } from '../helpers/linkData'

export default function useSnowplow() {
  const _snowplowScript = () => {
    if (window.snowplow) {
      return
    }

    ;(function (p, l, o, w, i, n, g) {
      if (!p[i]) {
        p.GlobalSnowplowNamespace = p.GlobalSnowplowNamespace || []
        p.GlobalSnowplowNamespace.push(i)
        p[i] = function () {
          ;(p[i].q = p[i].q || []).push(arguments)
        }
        p[i].q = p[i].q || []
        n = l.createElement(o)
        g = l.getElementsByTagName(o)[0]
        n.async = 1
        n.src = w
        g.parentNode.insertBefore(n, g)
      }
    })(
      window,
      document,
      'script',
      '//cdn.jsdelivr.net/npm/@snowplow/javascript-tracker@3.6.0/dist/sp.min.js',
      'snowplow',
    )
  }

  const _snowplowInit = () => {
    window.snowplow(
      'newTracker',
      'cf',
      process.env.NEXT_PUBLIC_SNOWPLOW_COLLECTOR_ENDPOINT,
      {
        appId: 'ar_web_app',
        platform: 'web',
        post: true,
        discoverRootDomain: true,
        contexts: {
          webPage: true,
          performanceTiming: true,
        },
      },
    )
  }

  const snowplowSetUser = (userId) => {
    if (!userId) {
      return
    }

    window.snowplow('setUserId', userId)
  }

  const _enableClickTracking = () => {
    document.onclick = (e) => {
      const data = linkData(e.target)

      if (!data) {
        return
      }

      const snowPlowData = Object.keys(data).reduce((acc, key) => {
        const _acc = acc

        if (data[key] !== undefined) {
          _acc[key] = data[key]
        }

        return _acc
      }, {})

      snowplowTrack(snowPlowData)
    }
  }

  const snowplowInit = (userId) => {
    _snowplowScript()
    _snowplowInit()
    _enableClickTracking()
    snowplowSetUser(userId)
    snowplowTrackPageView() // initial page view
  }

  const snowplowTrackPageView = () => {
    if (window.snowplow) {
      window.snowplow('trackPageView')
    }
  }

  const snowplowTrack = ({ eventName, property, value, label }) => {
    window.snowplow('trackStructEvent', {
      category: 'web_app',
      action: eventName,
      property: property || '',
      value: value || '',
      label: label || '',
    })
  }

  return {
    snowplowSetUser,
    snowplowInit,
    snowplowTrackPageView,
    snowplowTrack,
  }
}
