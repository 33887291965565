import '../styles/globals.css'
import PropTypes from 'prop-types'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import '../i18n'
import 'tailwindcss/tailwind.css'
import 'react-toastify/dist/ReactToastify.css'
import useSnowplow from '../hooks/useSnowplow'
import useLocalStorageState from '../hooks/useLocalStorageState'

function AsanaRebelWeb({ Component, pageProps }) {
  const router = useRouter()
  const userState = useLocalStorageState('asanarebel.user', {})
  const user = userState[0]
  const getLayout = Component.getLayout || ((page) => page)
  const { snowplowInit, snowplowTrackPageView } = useSnowplow()

  useEffect(() => {
    snowplowInit(user.id)
  }, [])

  useEffect(() => {
    const handleRouteChange = () => {
      snowplowTrackPageView()
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return getLayout(
    <>
      <Head>
        <title>Asana Rebel</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Head>
      <Component {...pageProps} />
    </>,
  )
}

export default AsanaRebelWeb

AsanaRebelWeb.propTypes = {
  Component: PropTypes.any.isRequired,
  pageProps: PropTypes.any.isRequired,
}
