import { useState, useEffect, useRef } from 'react'

/**
 *
 * @param {String} key The key to set in localStorage for this value
 * @param {Object} defaultValue The value to use if it is not already in
 * localStorage
 * @param {{serialize: Function, deserialize: Function}} options
 * The serialize and deserialize functions to use (defaults to JSON.stringify
 * and JSON.parse respectively)
 */

export default function useLocalStorageState(
  key,
  defaultValue = '',
  { serialize = JSON.stringify, deserialize = JSON.parse } = {},
) {
  const [state, setState] = useState(() => {
    // eslint-disable-next-line no-undef
    const valueInLocalStorage = process.browser
      ? window.localStorage.getItem(key)
      : ''

    if (valueInLocalStorage) {
      return deserialize(valueInLocalStorage)
    }

    return typeof defaultValue === 'function' ? defaultValue() : defaultValue
  })

  const prevKeyRef = useRef(key)

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (process.browser) {
      const prevKey = prevKeyRef.current

      if (prevKey !== key) {
        window.localStorage.removeItem(prevKey)
      }
      prevKeyRef.current = key
      window.localStorage.setItem(key, serialize(state))
    }
  }, [key, state, serialize])

  return [state, setState]
}
